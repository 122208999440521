export const speiseplan = {    
    periode:  "16.09. - 20.09.2024",
    
    zusatz:  "11:30 Uhr - 13:30 Uhr",

    montag: {
        sichtbar: true,
        gericht: "Hausgemachte Tagessuppe",
        beigabe: "",
        preis: "7,00 €"
    },

    dienstag: {
        sichtbar: true,
        gericht: "Wurstgulasch",
        beigabe: "mit Nudeln",
        preis: "8,90"
    },


    mittwoch: {
        sichtbar: true,
        gericht: "Puten-Jägerpfanne",
        beigabe: "mit Krautsalat & Baguette",
        preis: "9,90 €"
    },

    donnerstag: {
        sichtbar: true,
        gericht: "paniertes Seelachsfilet",
        beigabe: "mit Kartoffelsalat",
        preis: "9,90"
    },

    freitag: {
        sichtbar: true,
        gericht: "Feiertag",
        beigabe: "Geöffnet von 14 - 17 Uhr",
        preis: ""
    },
};
